import React from "react";

function Ibenefits() {
  return (
    <>
      <section class="bg-white pt-5 pb-5">
        <div class="container">
          <div class="row">
            <div className="col-xl-12 col-lg-12 col-md-12">
              <div className="m-b30 text-center">
                <h2 class="ert678">ICC (PoSH) Members</h2>
                <p>
                  The Internal Complaints Committee (PoSH) is formed according to
                  the provisions of the Sexual Harassment of Women at Workplace
                  (Prevention, Prohibition and Redressal) Act, 2013 the Internal
                  Complaints Committee is constituted. The members are given
                  below.
                </p>

                <p>
                  Policy for Prevention of Sexual Harassment (PoSH): To create
                  and maintain a safe work environment, free from sexual
                  harassment & discrimination for all employees.
                </p>
              </div>
            </div>
          </div>
          <div className="section-content">
            <div id="no-more-tables">
              <table className="table-bordered  table-condensed cf wt-responsive-table ">
                <thead className="Icc-Data-table">
                  <tr>
                    <td>S.No.</td>
                    <td>Name</td>
                    <td>Designation</td>
                    <td>Email Id</td>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td data-title="Bank Name">1. </td>
                    <td data-title="ICICI Bank">Kavita Verma (F)</td>
                    <td data-title="ICICI Bank">Director</td>
                    <td data-title="ICICI Bank">kavita@shadowinfosystem.com</td>
                  </tr>
                  <tr className="icc-member-tr">
                    <td data-title="Bank Name">2. </td>
                    <td data-title="ICICI Bank">Kumar Rajesh (M)</td>
                    <td data-title="ICICI Bank">Founder & CEO</td>
                    <td data-title="ICICI Bank">rajesh@shadowinfosystem.com</td>
                  </tr>
                  <tr>
                    <td data-title="Bank Name">3. </td>
                    <td data-title="ICICI Bank">Adv. Somaya Chauhan (F)</td>
                    <td data-title="ICICI Bank">Legal Advisor</td>
                    <td data-title="ICICI Bank"> legal@shadowinfosystem.com</td>
                  </tr>
                  <tr>
                    <td data-title="Bank Name">4. </td>
                    <td data-title="ICICI Bank">Anamika Jha (F)</td>
                    <td data-title="ICICI Bank">Sr. HR Consultant </td>
                    <td data-title="ICICI Bank">anamikajha016@gmail.com</td>
                  </tr>
                  <tr className="icc-member-tr">
                    <td data-title="Bank Name">5. </td>
                    <td data-title="ICICI Bank">Sanskriti Chauhan (F)</td>
                    <td data-title="ICICI Bank">HR Manager</td>
                    <td data-title="ICICI Bank"> hr@shadowinfosystem.com</td>
                  </tr>
                  <tr>
                    <td data-title="Bank Name">6. </td>
                    <td data-title="ICICI Bank">Sandeep Kumar (M)</td>
                    <td data-title="ICICI Bank">Team Leader</td>
                    <td data-title="ICICI Bank">sandeep@shadowinfosystem.com</td>
                  </tr>
                  
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
      <section class="advertisers-service-sec pt-5 pb-5">
        <div class="container">
          <div class="row">
            <div className="col-xl-12 col-lg-12 col-md-12">
              <div className="m-b30 text-center">
                <h2 class="ert678">What is Sexual Harassment?</h2>
                <p>
                  Sexual Harassment is any kind of sexually determined behaviour
                  that is deemed unwelcome. Sexual Harassment can be either
                  words or gestures or both. It could be direct behaviour, or
                  through implication. According to the India laws and the
                  Supreme Court, sexual harassment could be in the form of:
                </p>
              </div>
            </div>
          </div>
          <div className="section-content">
            <div className="container">
              <div class="row">
                <div class="col-md-12">
                  <ul class="nav nav-tabs accour">
                    <li className="icc-list-style">
                      <h4>Physical advances and physical contact</h4>
                    </li>
                    <li className="icc-list-style">
                      <h4>Demand or request for sexual favours</h4>
                    </li>
                    <li className="icc-list-style">
                      <h4>Sexually coloured remarks</h4>
                    </li>
                    <li className="icc-list-style">
                      <h4>Showing pornography</h4>
                    </li>
                    <li className="icc-list-style">
                      <h4>Demand or request for sexual favours</h4>
                    </li>
                    <li className="icc-list-style">
                      <h4>
                        Any other unwelcome physical, verbal, non-verbal conduct
                        of a sexual nature
                      </h4>
                    </li>
                  </ul>
                </div>
                <p>
                  {" "}
                  <span className="icc-member-span"> Note: </span> Committee
                  Members, Employees and Interns must have to join ICC (PoSH) meeting /
                  training on 1st and 3th Saturday of every month.{" "}
                </p>
              </div>
            </div>
          </div>{" "}
        </div>
      </section>
    </>
  );
}

export default Ibenefits;
